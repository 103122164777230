import React, { useEffect, useState } from "react";
import { Container, Heading, IconContainer, Paragraph } from "./Backups";
import { AuthIcon, buttonIcons } from "images/icons/auth-homepage-icons";
import DownloadButton from "./DownloadButton";
import styled from "styled-components";
import { URLwithUTMSuffix } from "utils/download";

interface IProp {
    sectionIcon: AuthIcon;
}

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-block: 2%;
    margin-inline: 5%;
`;

export default function DownloadSection(props: IProp) {
    const [assetsMap, setAssetsMap] = useState<Map<string, string>>(new Map());

    useEffect(() => {
        getLatestReleaseAssetsByExtension("ente-io", "ente", "auth-v4")
            .then((map) => {
                setAssetsMap(map);
            })
            .catch((error) => {
                console.error("Failed to fetch assets:", error);
            });
    }, []);

    const icon = props.sectionIcon.iconLight;

    return (
        <Container id="download-auth">
            <IconContainer>{icon}</IconContainer>
            <Heading>Download Auth</Heading>
            <Paragraph>Available everywhere</Paragraph>
            <ButtonContainer>
                {buttonIcons.map((button) => {
                    switch (button.text) {
                        case "MacOS":
                            return (
                                <DownloadButton
                                    text={button.text}
                                    icon={button.icon}
                                    link={assetsMap.get("dmg") ?? button.link}
                                />
                            );
                        case "Windows":
                            return (
                                <DownloadButton
                                    text={button.text}
                                    icon={button.icon}
                                    link={assetsMap.get("exe") ?? button.link}
                                />
                            );
                        default:
                            return (
                                <DownloadButton
                                    text={button.text}
                                    icon={button.icon}
                                    link={URLwithUTMSuffix(button.link)}
                                />
                            );
                    }
                })}
            </ButtonContainer>
        </Container>
    );
}

interface GitHubReleaseAsset {
    name: string;
    browser_download_url: string;
}

interface GitHubRelease {
    tag_name: string;
    assets: GitHubReleaseAsset[];
}

async function getLatestReleaseAssetsByExtension(
    owner: string,
    repo: string,
    tagPrefix: string,
): Promise<Map<string, string>> {
    const apiUrl = `https://api.github.com/repos/${owner}/${repo}/releases`;

    try {
        // Fetch the list of releases
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error(`Error fetching releases: ${response.statusText}`);
        }

        const releases: GitHubRelease[] = await response.json();

        // Find the latest release with the specified tag prefix
        const latestRelease = releases.find((release) =>
            release.tag_name.startsWith(tagPrefix),
        );

        if (!latestRelease) {
            throw new Error(`No release found with tag prefix '${tagPrefix}'`);
        }

        const extensionMap = new Map<string, string>();

        // Iterate through the assets and group by file extension
        latestRelease.assets.forEach((asset) => {
            const fileName = asset.name;
            const downloadUrl = asset.browser_download_url;

            // Extract the file extension from the file name
            const extension = fileName.split(".").pop() || "";
            extensionMap.set(extension, downloadUrl);
        });

        return extensionMap;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
